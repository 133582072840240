import {store} from "@/store";

export const getCableName = function (cable) {
  let c = {}
  for (let item of ['name', 'city', 'voltage_level', 'section']) {
    c[item] = cable[item]?.trim()
    if (!c[item] || c[item] === undefined) {  // 没有填的情况
      c[item] = ''
    }
  }
  if (c.city === '市辖区') {
    c.city = cable.provincial
  }  // 处理直辖市
  c.city = c.city.replace('市', '')  // 去掉'市'
  if (c.name.includes(c.city)) {
    c.city = ''
  } // 不重复显示城市名
  if (c.name.includes(c.voltage_level)) {
    c.voltage_level = ''
  } // 不重复显示电压等级
  if (store.state.user.lang === 'zh') {
    return `${c.city}${c.voltage_level}${c.name}${c.section}`
  } else {
    let result = c.name
    if (c.voltage_level && c.voltage_level.length > 0) {
      result = `${result} (${c.voltage_level})`
    }
    if (c.section && c.section.length > 0) {
      result = c.section + ', ' + result
    }
    if (c.city && c.city.length > 0) {
      result = result + ', ' + c.city
    }
    return result
  }
}