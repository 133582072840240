<template>
  <div>
    <div v-if="tableData.length < 1" style="margin-top: 200px; display: flex; justify-content: center">
      <el-link size="large" style="font-size: 50px; line-height: 65px" @click="router.push('managecenter/alarms')">
        {{ t('alertCenter.pleaseVisitManageCenter') }}
      </el-link>
    </div>
    <el-table v-show="tableData.length > 0" :data="tableData" row-key="id" max-height="850px">
      <el-table-column :label="t('alertCenter.ofWhatCableAndBox')" min-width="240">
        <template #default="scope">
          <div class="nowrap">
            <el-link @click="jump2cable(scope.row.cableId)">
              {{ getCableName(store.getters['cables/getById'](scope.row.cableId)) }}
            </el-link>
            <BoxIcon :id="scope.row.boxId" size="petit"/>
            {{ scope.row.box }} ({{ scope.row.deviceCode }})
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('alertCenter.ofWhatDevice')" min-width="160">
        <template #default="scope">
          <div class="nowrap">
            <DeviceIcon :id="scope.row.devDeviceID" size="petit"/>
            <el-link @click="jump2device(scope.row.devDeviceID)">
              {{ scope.row.modelName }}
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('alertCenter.warningItem')" min-width="160">
        <template #default="{ row }">
          {{ store.state.user.lang === 'zh' ? row.valueLabel : row.dataCfg?.dataUnit?.keyword }}
        </template>
      </el-table-column>
      <el-table-column :label="t('alertCenter.warningValue')" prop="value" min-width="160"/>
      <el-table-column :label="t('alertCenter.timeOfLatest')" min-width="160">
        <template #default="scope">
          <span>{{ scope.row.latest }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('alertCenter.timeOfWarningOrConfirming')" min-width="160">
        <template #default="scope">
          <span v-if="scope.row.state===1">{{ scope.row.created }}</span>
          <span v-if="scope.row.state===2">{{ scope.row.confirmed }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('alertCenter.frequency')" prop="frequency" min-width="80"/>
      <el-table-column :label="t('alertCenter.operations')">
        <template #default="scope">
          <el-button
              size="small"
              :type="scope.row.buttonType"
              @click="handleChangeState(scope.row)"
              @mouseover="scope.row.buttonText = scope.row.buttonTextOptions[1]"
              @mouseleave="scope.row.buttonText = scope.row.buttonTextOptions[0]">
            {{ scope.row.buttonText }}
          </el-button>
        </template>

      </el-table-column>
    </el-table>
  </div>
  <div class="pagination">
    <el-pagination layout="total, sizes, prev, pager, next, jumper"
                   :current-page="page"
                   :page-size="pageSize"
                   :page-sizes="[25, 50, 75, 100]"
                   :total="total"
                   @current-change="handlePageChange"
                   @size-change="handleSizeChange"/>
  </div>
  <div class="form">
    <el-dialog v-model="dialogVisible" :title="dialogTitle">
      <el-form>
        <el-form-item v-if="form.state === 1" :label="t('alertCenter.confirmInfo')">
          <el-input v-model="form.info" type="textarea"/>
        </el-form-item>
        <el-form-item v-if="form.state === 2" :label="t('alertCenter.solveInfo')">
          <el-input v-model="form.info" type="textarea"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">{{ t('alertCenter.cancel') }}</el-button>
          <el-button size="small" type="primary" @click="enterDialog">{{ t('alertCenter.confirm') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue"
import {useStore} from "vuex"
import {formatTimeToStr} from "@/utils/date"
import {ElMessage} from 'element-plus'
import {getAlarms, patchAlarms} from "@/api/alarms";
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import {useRouter} from "vue-router"
import noData from "@/view/dashboard/components/noData";
import {getCableName} from '@/utils/cableNameGen'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()
const router = useRouter()

const dialogVisible = ref(false)
const dialogTitle = ref('')

const tableData = ref([])
const total = ref(0)
const page = ref(1)
const pageSize = ref(25)

const nullForm = {
  info: '',
  id: 0,
  state: 0,
}

const form = ref({...nullForm})

const handleChangeState = function (row) {
  dialogTitle.value = row.buttonTextOptions[1]
  dialogVisible.value = true
  form.value.id = row.id
  form.value.state = row.state
}

const enterDialog = function () {
  let infoName = 'confirmInfo'
  let toState = 2
  let data = {}
  if (form.value.state === 2) {
    toState = 3
    infoName = 'repairInfo'
  }
  data[infoName] = form.value.info
  changeState(form.value.id, toState, data)
}

const closeDialog = function () {
  form.value = {...nullForm}
  dialogVisible.value = false
}

const handleSizeChange = function (value) {
  pageSize.value = value
  getTableData()
}

const handlePageChange = function (value) {
  page.value = value
  getTableData()
}

const changeState = async function (id, state, data) {
  const r = await patchAlarms({id, state, ...data})
  if (r.code === 0) {
    ElMessage({
      type: 'success',
      message: t('alertCenter.changeSuccess')
    })
    closeDialog()
    await getTableData()
  }
}

const getButtonTextOptions = function (state) {
  const unattended = t('alertCenter.buttonUnattended')
  const confirmed = t('alertCenter.buttonConfirmed')
  const toConfirm = t('alertCenter.buttonToConfirm')
  const toRepair = t('alertCenter.buttonToRepair')
  if (state === 1) {
    return [unattended, toConfirm]
  } else if (state === 2) {
    return [confirmed, toRepair]
  }
}

const getButtonType = function (state) {
  if (state === 1) {
    return 'danger'
  } else if (state === 2) {
    return 'warning'
  }
}

const getTableData = async function () {
  const table = await getAlarms({
    state: '1+2',
    page: page.value,
    pageSize: pageSize.value,
    ext: true
  })
  let tableD = []
  if (table.code === 0) {
    let devices = {}
    for (let alarm of table.data.list) {
      // 暂时隐藏掉问题
      if (!alarm.dataCfg) {
        continue
      }

      let deviceInfo = devices[alarm.devDeviceID]
      if (deviceInfo === undefined) {
        deviceInfo = devices[alarm.devDeviceID] = getDeviceInfo(parseInt(alarm.devDeviceID))
      }
      let valueUnit = alarm.dataCfg.dataUnit.unit
      let valueLabel = alarm.dataCfg.dataUnit.name
      let value = alarm.value + valueUnit
      if (alarm.value === 65535) {
        value = '-'
      }
      // let state = stateMap[alarm.state]
      let created = formatTimeToStr(alarm.createdat)
      let latest = formatTimeToStr(alarm.updatedat)
      let confirmed = ''
      if (!alarm.confirmedat.startsWith("0001-01-01")) {
        confirmed = formatTimeToStr(alarm.confirmedat)
      }
      let repaired = ''
      if (!alarm.repairedat.startsWith("0001-01-01")) {
        repaired = formatTimeToStr(alarm.repairedat)
      }
      const buttonOptions = getButtonTextOptions(alarm.state)
      tableD.push({
        ...alarm,
        ...deviceInfo,
        value,
        valueLabel,
        created,
        latest,
        confirmed,
        repaired,
        buttonText: buttonOptions[0],
        buttonTextOptions: buttonOptions,
        buttonType: getButtonType(alarm.state)
      })
    }
    tableData.value = tableD
    total.value = table.data.total
    page.value = table.data.page
    pageSize.value = table.data.pageSize
  }
}

const prepare = async function () {
  await store.dispatch('cables/request')
  await store.dispatch('companies/request')
}

const getDeviceInfo = function (deviceId) {
  let d = {}
  let device = store.getters['cables/getDeviceById'](deviceId)
  d.name = device.name
  if (device.model) {
    d.modelName = device.model.name
  } else {
    d.modelName = ''
  }
  let box = store.getters['cables/getDeviceById'](device.boxId)
  d.cable = store.getters['cables/getById'](box.cableId).name
  d.cableId = box.cableId
  d.box = box.name
  d.boxId = box.id
  d.boxModel = box.model?.name
  d.company = store.getters['companies/getById'](device.companyId)?.name
  d.maintainer = store.getters['companies/getById'](device.maintenanceId)?.name
  d.manufacturer = store.getters['companies/getById'](device.manufacturerId)?.name
  d.deviceCode = device.deviceCode
  return d
}

onMounted(
    () => {
      prepare()
          .then(() => getTableData()
          )
    }
)

function jump2cable(cableId) {
  store.commit('cables/setCurrent', cableId)
  router.push('cablecenter')
}

function jump2device(deviceId) {
  store.commit('cables/setCurrentDevice', deviceId)
  router.push('devicecenter')
}

</script>

<style scoped>
.pagination {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

.nowrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
</style>