<template>
  <div :class="size">
    <svg viewBox="0 0 24 24">
      <path d="M21,17V5H21M21,3H3A2,2 0 0,0 1,5V17A2,2 0 0,0 3,19H8V21H16V19H21A2,2 0 0,0 23,17V5A2,2 0 0,0 21,3Z"
            :fill="color"/>
      <text x="50%" y="50%" font-size="10" fill="white" font-family="system-ui, sans-serif" text-anchor="middle"
            dominant-baseline="middle">
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script setup>
import {ref, onMounted, onUpdated, watch} from "vue"
import {useStore} from "vuex"

const store = useStore()
const props = defineProps(['id', 'size'])

const name = ref('？')
const color = ref('green')
const size = ref('father-small')

function load() {
  if (props.size === undefined) {
    size.value = 'father-small'
  } else {
    size.value = 'father-' + props.size
  }
  let device = store.getters['cables/getDeviceById'](props.id)
  name.value = device.model?.name.slice(0,1)
  if (store.getters['cables/isDeviceAlarm'](props.id)) {
    color.value = '#D92525'
  } else if (store.getters['cables/getDeviceValueById'](props.id).online) {
    color.value = 'green'
  } else {
    color.value = '#878787'
  }
}

onMounted(()=>load())

onUpdated(()=>load())

watch(()=>store.state.cables.actionStatus.values,
    ()=>load())

</script>

<style scoped>
.father-small {
  width: 35px;
  height: 35px;
}

.father-big {
  width: 40px;
  height: 40px;
}

.father-petit {
  width: 30px;
  height: 30px;
}

.father-grand {
  width: 60px;
  height: 60px;
}
</style>
