<template>
  <div :class="sizeClass">
    <svg viewBox="0 0 24 24">
      <path d="M12 3L2 12H5V20H19V12H22L12 3"
            :fill="color" :class="props.shadow? 'box-shadow' : ''"/>
      <text x="50%" y="60%" font-size="10" fill="white" font-family="system-ui, sans-serif" text-anchor="middle"
            dominant-baseline="middle">
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script setup>
import {useStore} from "vuex"
import {onMounted, onUpdated, watch, ref} from "vue"

const props = defineProps(['id', 'shadow', 'size'])

const store = useStore()

const color = ref('green')
const name = ref('？')
const sizeClass = ref('father-small')

function isBoxOnline(boxId) {
  let box = store.getters['cables/getDeviceById'](boxId)
  if (!box.subdevs || box.subdevs.length < 1) {
    return false
  }
  for (let device of box.subdevs) {
    if (!store.getters['cables/getDeviceValueById'](device.id).online) {
      return false
    }
  }
  return true
}

function load() {
  let box = store.getters['cables/getDeviceById'](props.id)
  name.value = box.model?.name.slice(0, 1)
  if (props.size === 'big') {
    sizeClass.value = 'father'
  } else if (props.size === 'petit') {
    sizeClass.value = 'father-petit'
  } else if (props.size === 'xs') {
    sizeClass.value = 'father-xs'
  }
  if (store.getters['cables/isDeviceAlarm'](props.id)) {
    color.value = '#D92525'
  } else if (isBoxOnline(props.id)) {
    color.value = 'green'
  } else {
    color.value = '#878787'
  }
}

onMounted(() => {
  if (store.state.cables.actionStatus.values) {
    load()
  }
})

onUpdated(() => load())

watch(() => store.state.cables.actionStatus.values,
    () => load())

</script>


<style scoped>
.father {
  width: 45px;
  height: 45px;
}

.father-small {
  width: 40px;
  height: 40px;
}

.father-petit {
  width: 30px;
  height: 30px;
}

.father-xs {
  width: 25px;
  height: 25px;
}

.box-shadow {
  filter: drop-shadow(3px 3px 2px #202022);
}
</style>