<template>
  <div>{{ t('cableCenter.noData') }}</div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<style scoped>

</style>